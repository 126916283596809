/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
'use strict'

import type { GatsbyBrowser } from 'gatsby'

import '@/styles/main.scss'
import 'lazysizes'

require(`prismjs/themes/prism-okaidia.css`)

export const onServiceWorkerUpdateReady: GatsbyBrowser['onServiceWorkerUpdateReady'] = (): void => {
	const answer = window.confirm(`This site has been updated. ` + `Reload to display the latest version?`)
	if (answer === true) {
		window.location.reload()
	}
}
